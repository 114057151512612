import React from 'react'

import SEO from "../components/seo";
import LayoutSub from "../components/layoutSub";
import ExLink from "../components/exLink";

export default function Privacy( {location, pageContext} ) {

  const { breadcrumb: { crumbs } } = pageContext;
  const {pathname} = location;

  const pagetitle = "プライバシーポリシー";
  const description = "西荻窪の整体院・ぺんぎん堂へのプライバシーポリシーです。";

  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";
   
    return (

      <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop}>
        <SEO
          pageurl={pathname}
          pagetitle={pagetitle}
          description={description}
        />

        <h1 className="heading-b">プライバシーポリシー</h1>

        <p>ぺんぎん堂のサイトにお越しいただき、ありがとうございます。</p>

        <p>当サイトでは、ご利用いただく方の個人情報について、個人情報の保護に関する法律やその他の関係法令を遵守し、以下に定めるプライバシーポリシーに従って適切に取り扱うものといたします。</p>

        <p>以下に当サイトのプライバシーポリシーを記載します。当ブログにおける個人情報の取扱いについて、以下のとおりにプライバシーポリシーを定めます。
        </p>


        <h2 className="heading-l">個人情報の利用目的</h2>

        <p>当サイトでは、メールでのお問い合わせの際に、お名前・メールアドレス等の個人情報をご登録いただきます。</p>

        <p>これらの個人情報は、質問に対する回答や必要な情報をご連絡するために利用し、それ以外の目的では利用しません。</p>


        <h2 className="heading-l">個人情報の第三者への開示</h2>

        <p>個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。</p>
        <ul><li>本人のご了解がある場合</li>
          <li>法令等への協力のため、開示が必要となる場合</li></ul>

        <h2 className="heading-l">個人情報の開示・訂正・追加・削除・利用停止</h2>

        <p>個人情報の開示・訂正・追加・削除・利用停止をご希望の場合には、ご本人であることを確認したうえで、速やかに対応致します。</p>


        <h2 className="heading-l">Cookieについて</h2>

        <p>当ブログでは、一部のコンテンツにおいてCookieを利用しています。
          Cookieとは、webコンテンツへのアクセスに関する情報であり、お名前・メールアドレス等は含まれません。</p>

        <p>また、お使いのブラウザ設定からCookieを無効にすることが可能です。</p>


        <h2 className="heading-l">アクセス解析ツールについて</h2>

        <p>当サイトでは、Google Inc.が提供するアクセス解析ツール「Googleアナリティクス（Googleシグナルを含む）」と「Googleタグマネージャー」を利用しています。GoogleアナリティクスとGoogleタグマネージャーは、トラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ます。</p>

        <p>
          Googleアナリティクスの詳細は
          <ExLink href="https://marketingplatform.google.com/about/analytics/terms/jp/" >「Googleアナリティクス利用規約」</ExLink>をご覧ください。</p>

        <p>Googleタグマネージャーの詳細は<ExLink href="https://www.google.com/intl/ja/analytics/tag-manager/use-policy/" >「Googleタグマネージャ利用規約」</ExLink>をご覧ください。</p>

        <h2 className="heading-l">広告について</h2>

<p>当サイトは、第三者配信の広告サービス（Googleアドセンス）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。
クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。</p>

<p>
Cookieを無効にする方法やGoogleアドセンスに関する詳細は<ExLink href="https://policies.google.com/technologies/ads?gl=jp">「広告 – ポリシーと規約 – Google」</ExLink>をご確認ください。
</p>



        <h2 className="heading-l">著作権について</h2>

        <p>当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属します。権利を侵害する目的ではありません。</p>

        <p>記事の内容や掲載画像等に問題がある場合、各権利所有者様本人が直接メールでご連絡下さい。本人確認後、対応致します。</p>

        <p>また、当サイトのコンテンツ（記事・画像・その他プログラム）について、許可なく転載することを禁じます。引用の際は、当サイトへのリンクを掲載するとともに、転載であることを明記してください。</p>


        <h2 className="heading-l">免責事項</h2>

        <p>当サイトからリンクやバナーなどによって他のサイトに移動した場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。</p>

        <p>当サイトのコンテンツについて、可能な限り正確な情報を掲載するよう努めていますが、誤情報が入り込んだり、情報が古くなっている場合があります。当ブログに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>


        <h2 className="heading-l">プライバシーポリシーの変更について</h2>

        <p>当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。</p>

        <p>修正された最新のプライバシーポリシーは常に本ページにて開示されます。</p>


        <h2 className="heading-l">運営者情報</h2>

        <p>
          運営者：橋本智子<br />
          お問い合わせ：070-4200-8493
        </p>

        <p>
          初出掲載：2019年4月11日<br />
          最終更新：2022年4月11日
        </p>


      </LayoutSub>

       
         
    )
}